import React from 'react';
import { Link } from 'gatsby';
import { Seo } from '../../../modules/common/components/seo';
import { DashboardLayout } from '../../../modules/layout/components/dashboard-layout';
import { StaticImage } from 'gatsby-plugin-image';
import { SectionHeader } from '../../../modules/common/components/section-header';

import '../../generic-page.scss';
import '../index.scss';
import './guide.scss';
import { Col, Row, Table } from 'react-bootstrap';
import { CategoryCard } from '../../../modules/cs/main/components/category-card';
import { WWSet } from '../../../modules/ww/common/components/ww-set';

const WWGuidesEchoesExplained: React.FC = () => {
  return (
    <DashboardLayout className={'generic-page ww-guide'} game="ww">
      <ul className="breadcrumb">
        <li>
          <Link to="/wuthering-waves/">Wuthering Waves</Link>
        </li>
        <li className="divider">/</li>
        <li>
          <Link to="/wuthering-waves/guides">Guides</Link>
        </li>
        <li className="divider">/</li>
        <li>Echoes System Explained</li>
      </ul>
      <div className="page-header">
        <div className="page-icon">
          <StaticImage
            src="../../../images/ww/categories/category_echo.png"
            alt="Guides"
          />
        </div>
        <div className="page-details">
          <h1>Wuthering Waves Echoes System Explained</h1>
          <h2>
            The Echo System in Wuthering Waves explained - how they work, how to
            get them and more!
          </h2>
          <p>
            Last updated: <strong>04/05/2024</strong>
          </p>
        </div>
      </div>
      <div className="fuse-ad-placeholder" data-fuse="22844297235"></div>
      <div className="page-content">
        <SectionHeader title="Echoes" />
        <StaticImage src="../../../images/ww/generic/reso_4.webp" alt="Guide" />
        <p>
          <strong>
            Echoes are a type of equipment in this game and serve the function
            of armor and accessories in a traditional RPG game.
          </strong>{' '}
          Equipping Echoes to your Resonator will provide them with a variety of
          stat increases from HP to ATK to Energy Regeneration. Echoes will
          provide your Resonators with the main bulk of their stat increases.
        </p>
        <ul>
          <li>
            Every Echo has an <strong>active Echo ability</strong>.
          </li>
          <li>
            <strong>There are 5 total Echo slots</strong>. The Echo equipped in
            the first slot will have its active Echo Ability available for you
            to use. The order of the rest does not matter.
          </li>
          <li>
            Each Echo comes with a COST requirement that ranges between 1 to 4
            depending on the Echo’s Class.{' '}
            <strong>
              Each Resonator has a COST limit of 10 at the start and it is
              possible to upgrade it to 12 through Data Dock upgrades.
            </strong>{' '}
            Resonators can only equip Echoes up to the COST limit.
          </li>
        </ul>
        <p>There are 4 different Echo Classes:</p>
        <ul>
          <li>
            <strong className="Common">Common Class</strong> (1-COST)
          </li>
          <li>
            <strong className="Elite">Elite Class</strong> (3-COST)
          </li>
          <li>
            <strong className="Overlord">Overlord Class</strong> (4-COST)
          </li>
          <li>
            <strong className="Calamity">Calamity Class</strong> (4-COST)
          </li>
        </ul>
        <p>
          Each Echo has 2 main stats and up to 5 sub-stats. Primary main stats
          differ while secondary main stats are set for each Echo Class.
          Depending on the Echo Class, the possible{' '}
          <strong>primary main stats</strong> are:{' '}
        </p>
        <ul>
          <li>
            <strong className="Common">Common Class</strong> - HP%, ATK%, DEF%
          </li>
          <li>
            <strong className="Elite">Elite Class</strong> - HP%, ATK%, DEF%,
            Energy Regen%, Elemental Damage Boost*
          </li>
          <li>
            <strong className="Overlord">Overlord Class</strong> - HP%, ATK%,
            DEF% Crit Rate%, Crit DMG%, Healing Bonus%
          </li>
          <li>
            <strong className="Calamity">Calamity Class</strong> - HP%, ATK%,
            DEF% Crit Rate%, Crit DMG%, Healing Bonus%
          </li>
        </ul>
        <p>
          *Note that Elemental Damage Boost will be one of the 6 possible
          elements. It is not an All Elemental Damage Boost.
        </p>
        <p>
          Depending on the Echo Class, the set{' '}
          <strong>secondary main stats</strong> are:
        </p>
        <ul>
          <li>
            <strong className="Common">Common Class</strong> - Flat HP
          </li>
          <li>
            <strong className="Elite">Elite Class</strong> - Flat ATK
          </li>
          <li>
            <strong className="Overlord">Overlord Class</strong> - Flat ATK
          </li>
          <li>
            <strong className="Calamity">Calamity Class</strong> - Flat ATK
          </li>
        </ul>
        <p>
          Echo sub-stats are randomly generated every time you upgrade it by 5
          levels. And the sub-stats can be the same as main stats. The list of
          possible substats are:
        </p>
        <ul>
          <li>Flat HP</li>
          <li>Flat ATK</li>
          <li>Flat DEF</li>
          <li>HP%</li>
          <li>ATK%</li>
          <li>DEF%</li>
          <li>Crit Rate%</li>
          <li>Crit DMG%</li>
          <li>Energy Regen</li>
          <li>Basic Attack DMG Bonus%</li>
          <li>Heavy Attack DMG Bonus%</li>
          <li>Resonance Skill DMG Bonus%</li>
          <li>Resonance Liberation DMG Bonus</li>
        </ul>
        <p>
          Echoes can also be{' '}
          <strong>leveled up to increase the main stat bonuses</strong>, but the
          sub-stats need to be unlocked via tuning and they can’t be changed or
          increased. The maximum level of an Echo is determined by its rarity
          which ranges from Rank 2 to Rank 5. The higher the rarity of an Echo,
          the higher the stats it will have as its base level and when upgraded.{' '}
        </p>
        <p>
          The table below shows the Echo information in an easier form to read:
        </p>
        <Table striped bordered responsive>
          <thead>
            <tr>
              <th>Rarity</th>
              <th>Color</th>
              <th>Max level</th>
              <th>Max number of sub-stats</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>
                <strong className="Rank-2">Rank 2</strong>
              </td>
              <td>
                <strong className="Rank-2">Green</strong>
              </td>
              <td>10</td>
              <td>0</td>
            </tr>
            <tr>
              <td>
                <strong className="Rank-3">Rank 3</strong>
              </td>
              <td>
                <strong className="Rank-3">Blue</strong>
              </td>
              <td>15</td>
              <td>3</td>
            </tr>
            <tr>
              <td>
                <strong className="Rank-4">Rank 4</strong>
              </td>
              <td>
                <strong className="Rank-4">Purple</strong>
              </td>
              <td>20</td>
              <td>4</td>
            </tr>
            <tr>
              <td>
                <strong className="Rank-5">Rank 5</strong>
              </td>
              <td>
                <strong className="Rank-5">Gold</strong>
              </td>
              <td>25</td>
              <td>5</td>
            </tr>
          </tbody>
        </Table>
        <SectionHeader title="Tuning" />
        <StaticImage
          src="../../../images/ww/generic/echo_tune.webp"
          alt="Guide"
        />
        <p>
          Tuning is the mechanic that allows you to{' '}
          <strong>unlock sub-stats of Echoes</strong>. A sub-stat slot will be
          available for tuning for every 5 levels an Echo has with a maximum of
          5 slots at level 25. Tuning requires materials that can only be
          obtained via Tacet Field Cleanup (excluding one time rewards){' '}
          <strong>
            so it is advised to save them for Echoes that have the desired main
            stat
          </strong>
          . Also note that different rarity Echoes use different Tuning
          materials.
        </p>
        <SectionHeader title="Collecting Echoes" />
        <StaticImage
          src="../../../images/ww/generic/echo_capture.webp"
          alt="Guide"
        />
        <p>
          When a Tacet Discord (monster enemies) is defeated, there is a chance
          that it will leave an Echo behind for you to absorb. The drop rate and
          the rarity of the Echo absorbed depends on your Data Dock level.
        </p>
        <p>
          <strong>Enemies in the world will reset daily.</strong> This timer is
          unique to each world so it is possible to join another person’s world
          to farm more Echoes (keep in mind that co-op unlocks at Union Level
          30). The loot in co-op is personal so every player in the instance has
          a chance to get an Echo after a kill.{' '}
          <strong>
            This means that you can infinitely and more effectively farm Echoes
            by grouping up with other players and rotating worlds.
          </strong>
        </p>
        <SectionHeader title="Data Bank" />
        <StaticImage
          src="../../../images/ww/generic/echo_bank.webp"
          alt="Guide"
        />
        <p>
          Data Bank is the menu where you can check your Data Dock progress,
          check which type of Echoes you have obtained so far and more. Data
          Bank has 3 Sections; Data Dock, Echo Gallery, and Sonata Gallery.
        </p>
        <h5>Data Dock</h5>
        <p>
          Data Dock is the function that determines your Echo drop date, max
          absorbable rarity, enhanced absorption probability, and COST limit.
        </p>
        <ul>
          <li>
            Echo drop rate{' '}
            <strong>starts from 10% and can go up to 20% maximum</strong> at
            Data Dock level 10.
          </li>
          <li>
            Max absorbable rarity dictates the highest rarity you can obtain
            while absorbing an Echo. It starts from{' '}
            <strong className="rank-2">Rank 2</strong> and can go up to{' '}
            <strong className="rank-5">Rank 5</strong> at Data Dock level 15.
            Keep in mind that you can only obtain one rarity below the max
            absorbable rarity. For example, if your max absorbable rarity is
            currently <strong className="rank-4">Rank 4</strong>, the lowest
            rarity of Echo you can obtain is{' '}
            <strong className="rank-3">Rank 3</strong>.
          </li>
          <li>
            Enhanced absorption probability refers to the % chance of you
            obtaining the highest rarity of Echoes you can at the moment. This
            probability starts from 20% and can go up to 100% at Data Dock level
            20.
          </li>
          <li>
            Resonators can only equip{' '}
            <strong>Echoes up to the COST limit</strong>, this number goes up
            from 10 to 12 at Data Dock level 9.
          </li>
          <li>
            To upgrade your Data Dock, you’ll need to collect unique Echoes.{' '}
          </li>
          <li>
            Each unique Echo can provide up to 55 Data Dock EXP depending on the
            maximum rarity absorbed.
          </li>
        </ul>
        <h5>Echo Gallery</h5>
        <StaticImage
          src="../../../images/ww/generic/echo_gallery.webp"
          alt="Guide"
        />
        <p>
          Echo Gallery is an Echo compendium that allows you to check your Echo
          collection progress, Data Dock EXP gained from each Echo Type,
          possible Sonata(Set) Effect the Echo can have, and track them in the
          map.
        </p>
        <h5>Sonata Gallery</h5>
        <StaticImage
          src="../../../images/ww/generic/echo_sonata.webp"
          alt="Guide"
        />
        <p>
          Sonata Gallery allows you to check each Sonata(Set) Effect, what they
          do and which Echoes can have the selected Sonata Effect with ease.
        </p>
        <SectionHeader title="Sonata Effects / Set Bonuses" />
        <p>
          Echoes also come in sets and equipping multiple Echoes from the same
          set will provide additional bonuses for the Resonator. There are
          currently 9 different sets and each of them have 2 and 5-piece
          bonuses. Note that equipping the same Echo multiple times will not be
          counted for set bonuses.
        </p>
        <Row xs={1} xxl={2} className="relic-set-container">
          <Col>
            <WWSet name="Freezing Frost" mode="card" />
          </Col>
          <Col>
            <WWSet name="Molten Rift" mode="card" />
          </Col>
          <Col>
            <WWSet name="Void Thunder" mode="card" />
          </Col>
          <Col>
            <WWSet name="Sierra Gale" mode="card" />
          </Col>
          <Col>
            <WWSet name="Celestial Light" mode="card" />
          </Col>
          <Col>
            <WWSet name="Havoc Eclipse" mode="card" />
          </Col>
          <Col>
            <WWSet name="Rejuvenating Glow" mode="card" />
          </Col>
          <Col>
            <WWSet name="Moonlit Clouds" mode="card" />
          </Col>
          <Col>
            <WWSet name="Endless Resonance" mode="card" />
          </Col>
        </Row>
        <SectionHeader title="More information" />
        <p>To learn more about Echo Stats check our other guide:</p>
        <Row
          xs={1}
          md={2}
          lg={2}
          xxl={3}
          className="g-3 category-cards-container"
        >
          <CategoryCard
            title="Echo Stats"
            link="/wuthering-waves/guides/echo-stats"
            image={
              <StaticImage
                src="../../../images/ww/categories/category_echo3.png"
                alt="Echo stats"
              />
            }
          />
        </Row>
      </div>
      <div className="fuse-ad-placeholder" data-fuse="22844297238"></div>
    </DashboardLayout>
  );
};

export default WWGuidesEchoesExplained;

export const Head: React.FC = () => (
  <Seo
    title="Echoes Explained | Wuthering Waves | Prydwen Institute"
    description="The Echo system in Wuthering Waves explained - how they work, how to get them and more!"
    game="ww"
  />
);
